import React, { useEffect } from 'react'
import Header from '../../components/common/header/Header';
import Footer from '../../components/common/footer/Footer';
import trust_icon from '../../assets/icons/trust.png';
import { TEAM } from '../../constants/team/team';
import FAQ from '../../components/common/faq/FAQ';
import { IMAGES } from '../../constants/images/images';


function About() {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);

    const Team = ({img, name, position}) => {
        return(
            <div className='w-full text-center'>
                <img src={img} className='h-[150px] w-[150px] rounded-full object-cover mx-auto' />
                <h2 className='font-semibold text-2xl mt-5'>{name}</h2>
                <p className='font-medium mt-2 opacity-70'>{position}</p>
            </div>
        )
    };

    return (
        <div>
            <Header />
            <div className='max-w-[1240px] mx-auto px-[20px] py-20'>
                <h1 className='font-bold text-4xl text-center max-w-[900px] mx-auto'>Belina wave is a logistics company dedicated to providing top notch services to <span className='text-bel-red'>clients all around the world</span></h1>
            </div>
            <div className='w-full bg-[#f5f5f5]'>
                <div className='max-w-[1240px] mx-auto px-[20px] py-10 flex flex-col md:grid grid-cols-[1fr_300px] items-center gap-5'>
                    <div>
                        <h3 className='font-bold text-2xl mb-4 text-bel-red'>About Us</h3>
                        <p className='opacity-70'>We take pride in being regarded as one of the most reliable and affordable logistic and warehousing service providers in the country. As a third party logistic service provider, we excel at a range of logistic services, which includes trucking services, warehousing services, logistic services, and a range of other ancillary services. We have years of experience in the business of logistics, warehousing, distribution, trucking and supply chain management services, and aim to provide our clients with convenience, reliability and affordability through our premium logistic services.</p>
                        <p className='mt-2 opacity-70'>Our experience in all the fields we serve in, and the range of services we provide, makes us one of the most comprehensive logistic service providers in the nation. And, with the help of continuous support and trust of our clients, we aim to stay at the top of the game, and humbly so. Our sophisticated systems, neatly designed logistic process, state of the art logistic tools and equipment, most advanced carriers, custom tailored services, and dedication to keep the costs low for end users, help us to provide logistic solution that aligns well with our clients’ requirements. We welcome you to our site, and request you to consult with our logistic experts for your logistic needs, and rest assured of getting done.</p>
                    </div>
                    <div className='w-full h-full flex items-center justify-center'>
                        <img src={trust_icon} className='max-h-[100px]' />
                    </div>
                </div>
            </div>
            <div className='max-w-[1240px] mx-auto px-[20px] py-10 flex flex-col md:grid grid-cols-2 gap-5 md:gap-10'>
                <img src={IMAGES.truck_4} className='h-full w-full rounded-xl md:rounded-[25px] object-cover' />
                <div className='grid grid-cols-2 md:flex flex-col gap-5 md:gap-10'>
                    <img src={IMAGES.plane_flying_2} className='max-h-[300px] w-full rounded-xl md:rounded-[25px] object-cover' />
                    <img src={IMAGES.warehouse_1} className='max-h-[300px] w-full rounded-xl md:rounded-[25px] object-cover' />
                </div>
            </div>
            <div className='max-w-[1240px] mx-auto px-[20px] py-10'>
                <h1 className='font-bold text-3xl'>The <span className='text-bel-red'>Team</span></h1>
                <p className='mt-2 font-medium'>Meet the brains behind Belina wave </p>
                <div className='flex flex-col md:grid grid-cols-3 gap-10 w-full mt-10'>
                    {TEAM.map((i, idx) => (
                        <Team
                            name={i.name}
                            position={i.position}
                            img={i.img}
                            key={idx}
                        />
                    ))}
                </div>
            </div>
            <FAQ />
            <Footer />
        </div>
    )
}

export default About