import React, { useEffect } from 'react'
import Header from '../../components/common/header/Header'
import DeliverParcel from '../../components/common/forms/deliverParcel/DeliverParcel'
import { IMAGES } from '../../constants/images/images'
import { FiGlobe, FiMail, FiMapPin } from 'react-icons/fi'
import { BsFillTelephoneFill } from 'react-icons/bs'
import Footer from '../../components/common/footer/Footer'
import { IoDocumentOutline } from 'react-icons/io5'

function Ship() {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);

    return (
        <div>
            <Header />
            <div className='max-w-[1240px] mx-auto px-[20px] py-20'>
                <h1 className='font-bold text-4xl text-center max-w-[900px] mx-auto'>Let us take care of your <span className='text-bel-red'>logistics needs</span></h1>
            </div>
            <div className='max-w-[1240px] mx-auto px-[20px]'>
                <div className='rounded-2xl bg-no-repeat bg-center bg-cover overflow-hidden mt-10' style={{backgroundImage: `url(${IMAGES.truck_2})`}} >
                <div className='h-full w-full bg-overlay-30 p-4 sm:p-10 sm:grid grid-cols-2 gap-10'>
                    <div className='text-white mt-5 mb-10 sm:mt-20'>
                    <h1 className='text-xl font-bold m-0 text-3xl'>Have a parcel that needs to be delivered?</h1>
                    <p className='mt-3'>Team of cargo experts are always available to help you with any queries you might have, or if you want to consult in length your logistic requirements. We would study your requirements and</p>
                    </div>
                    <DeliverParcel />
                </div>
                </div>
            </div>
            <div className='max-w-[1240px] mx-auto px-[20px] py-20'>
                <h1 className='text-xl font-bold m-0 text-3xl'>Contact <span className='text-bel-red'>Us</span></h1>
                <div className='flex items-center gap-10 flex-wrap mt-6'>
                <div className='flex items-center gap-2'>
                    <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <FiMapPin size={18} /> </div> <p>UNIT 6 GRENOGUE BUSINESS PLAZA, Ireland.</p>
                </div>
                <div className='flex items-center gap-2'>
                    <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <BsFillTelephoneFill size={16} /> </div> <p>+1 (814) 507-7448</p>
                </div>
                <div className='flex items-center gap-2'>
                    <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <FiMail size={20} /> </div> <p>contact@belinawave.com</p>
                </div>
                <div className='flex items-center gap-2'>
                    <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <IoDocumentOutline size={20} /> </div> <p>Registration number: IE482621</p>
                </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Ship