export const FREQUENTLY_ASKED_QUESTIONS = [
    {
        question: 'How do you ensure the safety and security of our cargo during transportation?',
        answer: 'At our belina wave logistics, cargo safety and security are paramount. We employ strict security protocols, real-time tracking systems, and well-maintained vehicles to safeguard your cargo throughout its journey. Additionally, our team undergoes rigorous training to handle goods with care and precision, minimizing the risk of damage or loss.'
    },
    {
        question: 'What sets your warehousing services apart from competitors?',
        answer: 'Our warehousing services stand out due to their comprehensive nature. We offer strategic locations, state-of-the-art facilities, and advanced technology for efficient storage and distribution. Additionally, our value-added services, including labeling, repackaging, and quality control, help clients optimize their supply chain and reduce operational costs.'
    },
    {
        question: 'Can you handle international shipping needs, including customs clearance and documentation?',
        answer: "Absolutely. Our logistics company specializes in international shipping. We have a global network of partners, in-depth knowledge of international regulations, and expertise in customs clearance and documentation. Whether you're importing or exporting, we provide end-to-end solutions for hassle-free global trade."
    },
    {
        question: 'How do you handle urgent shipments that require quick delivery?',
        answer: 'For time-sensitive shipments, our plane cargo services offer expedited transport solutions. We have partnerships with airlines and extensive experience in air cargo logistics to ensure swift and reliable delivery. Our commitment to efficiency and precision makes us the ideal choice for high-priority shipments.'
    }
]