export function convertTo12HourFormat(time24) {
    // Split the time into hours and minutes
    const [hours, minutes] = time24.split(':').map(Number);

    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12

    // Create the 12-hour formatted time string
    const time12 = `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;

    return time12;
}