import { ROUTE_PATHS } from "./constants/routes/routes";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import Services from "./pages/services";
import Ship from "./pages/ship/Ship";
import Tracking from "./pages/tracking/Tracking";

import {
  createBrowserRouter,
} from "react-router-dom";

export const routes = createBrowserRouter([
  {
    path: ROUTE_PATHS.PUBLIC.HOME,
    element: <Home />,
  },
  {
    path: ROUTE_PATHS.PUBLIC.TRACKING,
    element: <Tracking />,
  },
  {
    path: ROUTE_PATHS.PUBLIC.TRACKING_ID,
    element: <Tracking />,
  },
  {
    path: ROUTE_PATHS.PUBLIC.SHIP,
    element: <Ship />
  },
  {
    path: ROUTE_PATHS.PUBLIC.SERVICES,
    element: <Services />,
  },
  {
    path: ROUTE_PATHS.PUBLIC.ABOUT,
    element: <About />
  }
]);