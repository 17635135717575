import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTE_PATHS } from '../../../constants/routes/routes'

function Footer() {
  return (
    <div className='w-full bg-[#000]'>
        <div className='w-full h-[100px] bg-[#fff] rounded-b-[20px] md:rounded-b-[30px]' />
        <div className='w-full h-fit py-20'>
            <div className='max-w-[1240px] px-[20px] mx-auto text-white'>
                <h1 className='text-3xl font-bold'><span className='text-bel-red'>Belina Wave</span> Logistics</h1>
                <div className='w-full h-[1px] bg-white opacity-30 my-5'/>
                <div className='flex flex-wrap gap-20'>
                    <Link to={ROUTE_PATHS.PUBLIC.HOME}><p className='hover:text-bel-red text-lg font-medium'>Home</p></Link>
                    <Link to={ROUTE_PATHS.PUBLIC.ABOUT}><p className='hover:text-bel-red text-lg font-medium'>About Us</p></Link>
                    <Link to={ROUTE_PATHS.PUBLIC.TRACKING}><p className='hover:text-bel-red text-lg font-medium'>Tracking</p></Link>
                    <Link to={ROUTE_PATHS.PUBLIC.HOME}><p className='hover:text-bel-red text-lg font-medium'>Shipping</p></Link>
                    <div>
                        <Link to={ROUTE_PATHS.PUBLIC.SERVICES}> <h1 className='text-lg font-medium hover:text-bel-red'>Services</h1></Link>
                        <div className='flex flex-col gap-2 mt-4'>
                            <Link to={ROUTE_PATHS.PUBLIC.SERVICES}><p className='hover:text-bel-red opacity-80 font-medium'>Sea Freight</p></Link>
                            <Link to={ROUTE_PATHS.PUBLIC.SERVICES}><p className='hover:text-bel-red opacity-80 font-medium'>Air Cargo</p></Link>
                            <Link to={ROUTE_PATHS.PUBLIC.SERVICES}><p className='hover:text-bel-red opacity-80 font-medium'>Trucking</p></Link>
                            <Link to={ROUTE_PATHS.PUBLIC.SERVICES}><p className='hover:text-bel-red opacity-80 font-medium'>Ware Housing</p></Link>
                        </div>
                    </div>
                    <Link target="_blank" to={`mailto:contact@belinawave.com`}><p className='hover:text-bel-red text-lg font-medium'>Contact Us</p></Link>
                </div>
                <div className='w-full h-[1px] bg-white opacity-30 my-5'/>
                <p className='text-sm font-semibold opacity-70 mt-10'>Copyright © 2023 Belina wave logistics, All rights reserved</p>
            </div>
        </div>
    </div>
  )
}

export default Footer