import React, { useState } from 'react';
import { redirect, useNavigate } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { BANNER_DATA } from '../../../../constants/banner/banner';
import { Navigate } from "react-router-dom";
import { ROUTE_PATHS } from '../../../../constants/routes/routes';

const imgLink = 'https://media.wired.com/photos/590951f9d8c8646f38eef333/16:9/w_929,h_523,c_limit/walmart-advanced-vehicle-experience-wave-concept-truck.jpg';

function Banner() {
    const navigate = useNavigate();
    const [code, setCode] = useState('');

    const [current, setCurrent] = useState(BANNER_DATA[0]);
    const [active, setActive] = useState(0);
    const [animationName, setAnimationName] = useState('animate-[slideIn_0.7s_ease-in-out]')
    const [previousImage, setPreviousImage] = useState(BANNER_DATA[0].img);

    const handleClick = async () => {
        if(code.length > 0){
            navigate(ROUTE_PATHS.PUBLIC.TRACKING + '/' + code)
        }
    };

    const handlePrev = () => {
        setAnimationName('')
        setTimeout(() => {
            setAnimationName('animate-[slideOut_0.7s_ease-in-out]');
            if(active > 0){
                setPreviousImage(BANNER_DATA[active].img)
                setActive(prev => prev - 1);
                setCurrent(BANNER_DATA[active - 1])
            }else{
                setPreviousImage(BANNER_DATA[active].img)
                setActive(2)
                setCurrent(BANNER_DATA[2])
            }
        }, 100);
        
    }

    const handleNext = () => {
        setAnimationName('')
        // setPreviousImage()
        setTimeout(() => {
            setAnimationName('animate-[slideIn_0.7s_ease-in-out]');
            if(active < 2){
                setPreviousImage(BANNER_DATA[active].img)
                setActive(prev => prev + 1);
                setCurrent(BANNER_DATA[active + 1])
            }else{
                setPreviousImage(BANNER_DATA[active].img)
                setActive(0)
                setCurrent(BANNER_DATA[0])
            }
        }, 100);
        
    }

    return (
        <div className='bg-[#B9B9B9] object-cover bg-no-repeat bg-center bg-cover' style={{backgroundImage: `url(${previousImage})`}}>
            <div className='bg-overlay-50'>
            {/* {current} */}
                <div className={`${animationName} md:h-[100vh] w-[100vw] bg-bel-red object-cover bg-no-repeat bg-center bg-cover`} style={{backgroundImage: `url(${current.img})`}}>
                    <div className='h-full w-full bg-overlay-50 grid md:grid-cols-[50px_1fr_50px] px-[20px] py-20 md:p-10 box-border items-center gap-10'>
                        <div className='h-[50px] w-[50px] hidden rounded-full bg-black text-white md:flex items-center justify-center cursor-pointer' onClick={handlePrev} > <FiChevronLeft size={25} /> </div>
                        <div className='w-full h-full flex flex-col justify-center'>
                            <h1 className='text-white font-bold text-3xl md:text-5xl max-w-[900px] m-0'>{current.title}</h1>
                            <p className='text-white opaticy-70 m-0 mt-4 max-w-[900px]'>{current.text}</p>
                            <div className='flex w-full md:w-[400px] items-center h-[50px] mt-10 bg-white w-fit rounded-full overflow-hidden p-0.5'>
                                <input value={code} onChange={e => setCode(e.target.value)} className='h-full flex-1 px-3 box-border focus:outline-none' placeholder='Enter tracking code ...' />
                                <button className='h-full px-7 block md:hidden text-sm font-semibold bg-bel-red text-white cursor-pointer rounded-full' onClick={handleClick}>Track</button>
                                <button className='h-full px-7 hidden md:block text-sm font-semibold bg-bel-red text-white cursor-pointer rounded-full' onClick={handleClick}>Track Package</button>
                            </div>
                        </div>
                        <div className='h-[50px] w-[50px] hidden rounded-full bg-black text-white md:flex items-center justify-center cursor-pointer' onClick={handleNext}> <FiChevronRight size={25} /> </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner