import React, { useState } from 'react';

import { FiMinus, FiPlus } from 'react-icons/fi';
import { FREQUENTLY_ASKED_QUESTIONS } from '../../../constants/faq/faq';
import { IMAGES } from '../../../constants/images/images';
import { Link } from 'react-router-dom';

function FAQ() {
    const [showAnswer, setShowAnswer] = useState(null);

    const handleToggle = (id) => {
        if(showAnswer === id){
            setShowAnswer(null)
        }else{
            setShowAnswer(id)
        }
    }

    const Item = ({question, answer, id}) => {
        return(
            <div className='w-full flex flex-col gap-3'>
                <div className='w-full grid grid-cols-[1fr_40px] gap-3 items-center'>
                    <h3 className='font-semibold text-xl'>{question}</h3> 
                    <div onClick={() => handleToggle(id)} className='h-[40px] w-[40px] rounded-full flex items-center justify-center cursor-pointer hover:bg-[#f5f5f5]'>{showAnswer === id ? <FiMinus size={20} /> :  <FiPlus size={20} />}</div> 
                </div>
                {showAnswer === id && <p>{answer}</p>}
            </div>
        )
    };

    return (
        <div className='max-w-[1240px] mx-auto px-[20px] py-20'>
            <h1 className='font-bold text-3xl'>Frequently Asked <span className='text-bel-red'>Questions</span></h1>
            <div className='flex flex-col md:grid grid-cols-[1fr_450px] gap-10 mt-10'>
                <div className='flex flex-col gap-[30px]'>
                    {FREQUENTLY_ASKED_QUESTIONS.map((i, idx) => (
                        <div className='flex flex-col gap-[20px]' key={idx}>
                            <div className='w-full h-[1px] bg-black opacity-10' />
                            <Item
                                question={i.question}
                                answer={i.answer}
                                id={idx}
                            />
                        </div>
                    ))}
                </div>
                <div className='h-[400px] w-full rounded-lg bg-cover bg-no-repeat bg-center p-5 flex items-end' style={{backgroundImage: `url(${IMAGES.plane_flying_2})`}}>
                    <div className='h-fit w-full rounded-lg bg-white p-4'>
                        <h3 className='font-bold text-xl mb-5'>Still Got Questions?</h3>
                        <Link target="_blank" to={`mailto:contact@belinawave.com`}><button className='h-[40px] w-full bg-bel-red text-white font-semibold text-sm cursor-pointer rounded-full'>Send us and email</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ