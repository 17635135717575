import { IMAGES } from "../images/images";

export const SERVICES_DATA = [
    {
        title: 'Trucking',
        details: "Our trucking services are the backbone of our logistics solutions. We offer a fleet of modern, well-maintained vehicles and a highly skilled team of drivers to ensure your cargo reaches its destination safely and on time. Whether it's local or long-distance transport, our commitment to reliability is unwavering. We meticulously plan routes to optimize efficiency while minimizing costs, making us the preferred choice for businesses seeking cost-effective and punctual delivery solutions. With real-time tracking and a focus on safety, we provide peace of mind that your goods are in capable hands from pickup to delivery.",
        icon: 'cost',
        tag: 'Cost Efficent',
        img: IMAGES.truck_3
    },
    {
        title: 'Plane Cargo',
        details: "When time is of the essence, our cargo services provide the speed and reliability you need. We offer expedited air transport solutions for your urgent shipments. Our network of airline partnerships and expert knowledge of air cargo regulations ensure that your goods arrive at their destination swiftly and securely. We understand that in today's fast-paced world, delays can be costly, so we prioritize efficiency and precision in every aspect of our plane cargo operations. Trust us to deliver your high-priority shipments with the utmost care and timeliness.",
        icon: 'fast',
        tag: 'Fast Delivery',
        img: IMAGES.cargoloading2
    },
    {
        title: 'Ocean Freight',
        details: "Our ocean freight services are the key to connecting your business to the world. With a global network of partners and a deep understanding of international regulations, we offer seamless and cost-effective shipping solutions. Our expertise in container handling, customs clearance, and documentation ensures that your cargo flows smoothly across the seas. We prioritize safety and sustainability, making environmentally conscious choices in our shipping practices. Whether you're importing or exporting, our ocean freight services offer reliability and affordability that keep you competitive in the global market.",
        icon: 'large',
        tag: 'Larger parcel deliveries',
        img: IMAGES.ocean_Freight
    },
    {
        title: 'Warehousing',
        details: "Our warehousing services offer more than just storage; they provide tailored solutions to optimize your supply chain. Our state-of-the-art facilities are strategically located for easy access and distribution. From inventory management to order fulfillment, our team ensures your goods are handled with care and precision. We offer a range of value-added services, including labeling, repackaging, and quality control, to meet your specific requirements. With our advanced technology and dedicated staff, we create efficiencies that save you time and money, allowing you to focus on growing your business.",
        icon: 'secure',
        tag: 'Secured Storage Space',
        img: IMAGES.warehouse_2
    },
]