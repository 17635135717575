import team01 from '../../assets/images/team01.jpg';
import team02 from '../../assets/images/team02.png';
import team03 from '../../assets/images/team03.png';

export const TEAM = [
    {
        name: 'John Doe',
        position: 'Chief Finance Officer',
        img: team01
    },
    {
        name: 'Mary Kate',
        position: 'Head Of Operations',
        img: team02
    },
    {
        name: 'Millie Jannet',
        position: 'Human Resource',
        img: team03
    }
]