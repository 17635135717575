import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../constants/routes/routes';
import { FiMenu, FiX } from 'react-icons/fi';

function Header() {
  const [showDrawer, setShowDrawer] = useState(false);

  const SideDrawer = () => {
    return(
      <div className='w-full h-screen bg-overlay-50 fixed top-0 left-0 z-[1000] grid grid-cols-[1fr_300px]'>
        <div className='h-full w-full' onClick={() => setShowDrawer(prev => !prev)} />
        <div className='h-full w-full bg-white py-5 px-[20px] animate-[slideIn_0.7s_ease-in-out]'>
          <div className='flex items-center justify-end'>
            <div className='h-[45px] w-[45px] rounded-full border bg-[#f5f5f5] flex items-center justify-center cursor-pointer' onClick={() => setShowDrawer(prev => !prev)} > <FiX size={18} /> </div>
          </div>
          <div className='flex flex-col gap-10 mt-[60px]'>
            <Link to={ROUTE_PATHS.PUBLIC.SERVICES}> <div className='text-black text-base font-semibold cursor-pointer hover:text-bel-red text-center'>Services</div></Link>
            <Link to={ROUTE_PATHS.PUBLIC.ABOUT}> <div className='text-black text-base font-semibold cursor-pointer hover:text-bel-red text-center'>About</div> </Link>
            <Link to={ROUTE_PATHS.PUBLIC.TRACKING}> <div className='text-black text-base font-semibold cursor-pointer hover:text-bel-red text-center'>Tracking</div></Link>
            <Link to={ROUTE_PATHS.PUBLIC.SHIP}> <div className='text-black text-base font-semibold cursor-pointer hover:text-bel-red text-center'>Ship Package</div></Link>
            <Link target="_blank" to={`mailto:contact@belinawave.com`}><button className='w-full h-[45px] rounded-full bg-black text-sm text-white font-semibold cursor-pointer'>Contact Us</button></Link>
          </div>
        </div>
      </div>
    )
  };

  return (
    <>
      <div className='w-full bg-white'>
        <div className='max-w-[1240px] mx-auto py-5 px-[20px] grid grid-cols-[1fr_45px] md:grid-cols-[1fr_150px] gap-2 items-center'>
          <div className='flex items-center gap-20'>
            <Link to={ROUTE_PATHS.PUBLIC.HOME}> <h3 className='text-bel-red font-semibold m-0 text-xl cursor-pointer'>Belina Wave <span className='text-black'>Logistics</span></h3></Link>
              <div className='hidden md:flex flex items-center gap-10'>
                <Link to={ROUTE_PATHS.PUBLIC.SERVICES}> <div className='text-black text-base font-semibold cursor-pointer hover:text-bel-red'>Services</div></Link>
                <Link to={ROUTE_PATHS.PUBLIC.ABOUT}> <div className='text-black text-base font-semibold cursor-pointer hover:text-bel-red'>About</div> </Link>
                <Link to={ROUTE_PATHS.PUBLIC.TRACKING}> <div className='text-black text-base font-semibold cursor-pointer hover:text-bel-red'>Tracking</div></Link>
                <Link to={ROUTE_PATHS.PUBLIC.SHIP}> <div className='text-black text-base font-semibold cursor-pointer hover:text-bel-red'>Ship Package</div></Link>
              </div>
          </div>
          <div className='md:hidden h-[45px] w-[45px] rounded-full border bg-[#f5f5f5] flex items-center justify-center cursor-pointer' onClick={() => setShowDrawer(prev => !prev)} > <FiMenu size={18} /> </div>
          <Link target="_blank" to={`mailto:contact@belinawave.com`}><button className='w-full h-[45px] rounded-full bg-black text-sm text-white font-semibold cursor-pointer hidden md:block'>Contact Us</button></Link>
        </div>
      </div>
      {showDrawer && <SideDrawer />}
    </>
  )
}

export default Header