import React, { useEffect } from 'react'
import Header from '../../components/common/header/Header';

import Banner from '../../components/UI/home/banner/Banner';

import { BiLock, BiShield, BiSolidPlaneAlt, BiSupport } from 'react-icons/bi';
import { BsFillTelephoneFill, BsShieldFillCheck } from 'react-icons/bs';
import { IoDocumentOutline } from 'react-icons/io5'
import DeliverParcel from '../../components/common/forms/deliverParcel/DeliverParcel';
import { testimonials } from '../../constants/testimonials/testimonials';
import { FiGlobe, FiMail, FiMapPin } from 'react-icons/fi';
import Footer from '../../components/common/footer/Footer';
import { IMAGES } from '../../constants/images/images';

function Home() {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='w-full overflow-hidden'>
      <Header />
      <Banner />
      <div className='max-w-[1240px] mx-auto px-[20px] py-10'>
        <div className='grid grid-cols-2 w-full sm:flex items-center gap-20 mt-10'>
          <div>
            <h1 className='text-4xl font-black'>10,000+</h1>
            <p className='font-semibold opacity-70 mt-1'>Operational Vehicles</p>
          </div>
          <div>
            <h1 className='text-4xl font-black'>100k</h1>
            <p className='font-semibold opacity-70 mt-1'>Packages Delivered</p>
          </div>
          <div>
            <h1 className='text-4xl font-black'>5+</h1>
            <p className='font-semibold opacity-70 mt-1'>Years Experience</p>
          </div>
          <div>
            <h1 className='text-4xl font-black'>70k</h1>
            <p className='font-semibold opacity-70 mt-1'>Happy Clients</p>
          </div>
        </div>
        <div className='mt-20'>
          <h1 className='text-xl font-bold m-0 text-3xl'>Exceptional <span className='text-bel-red'>services</span> we provide</h1>
          <p className='text-base mt-3 max-w-[800px] opacity-70'>Our dedication to excellence extends to every aspect of our operations. From meticulously planned logistics solutions that optimize your supply chain to a commitment to uncompromising safety standards at every stage of the journey, our comprehensive suite of services is designed to meet and exceed your expectations. With a focus on innovation and a global reach that spans continents, we deliver results that are truly exceptional</p>
          <div className='flex flex-col sm:grid grid-cols-3 gap-10 mt-10'>
            <div>
              <img src={IMAGES.cargo_loading} className='rounded-xl w-full h-[200px] object-cover' />
              <p className='m-0 mt-3 font-semibold text-xl'>Cargo Shipping</p>
            </div>
            <div>
              <img src={IMAGES.truck_2} className='rounded-xl w-full h-[200px] object-cover' />
              <p className='m-0 mt-3 font-semibold text-xl'>Trucking Services</p>
            </div>
            <div>
              <img src={IMAGES.warehouse_1} className='rounded-xl w-full h-[200px] object-cover' />
              <p className='m-0 mt-3 font-semibold text-xl'>Warehousing</p>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full h-fit sm:grid grid-cols-2 mt-10'>
        <img src={IMAGES.trucking} className='w-full h-full object-cover' />
        <div className='w-full h-full p-10 box-border bg-[#f5f5f5]'>
          <h1 className='text-xl font-bold m-0 text-3xl'>About <span className='text-bel-red'>us</span></h1>
          <p className='mt-5 opacity-70 leading-[170%]'>We take pride in being regarded as one of the most reliable and affordable logistic and warehousing service providers in the country. As a third-party logistic service provider, we excel at a range of logistic services, which includes trucking services, warehousing services, logistic services, and a range of other ancillary services. We have years of experience in the business of logistics, warehousing, distribution, trucking, and supply chain management services. We aim to provide our clients with convenience, reliability, and affordability through our premium logistic services.<br /> Our team of experts at all levels of our services have years of experience backing them, which adds the credibility of an expert workforce. This also helps us in cutting down response time and providing punctual delivery and services at all times, whether it is trucking service or warehousing services. Our goal is to make a positive difference in your business through our services and build a long-term relationship with you.</p>
        </div>
      </div>
      <div className='max-w-[1240px] mx-auto px-[20px] mt-20'>
        <h1 className='text-xl font-bold m-0 text-3xl'>Why choose <span className='text-bel-red'>Belina Wave?</span></h1>
        <p className='text-base mt-3 max-w-[800px] opacity-70'>When it comes to selecting a logistics partner, the reasons to choose us are abundantly clear. Our unwavering commitment to delivering outstanding service, our extensive expertise honed over years of industry leadership, and our relentless pursuit of innovation set us apart</p>
        <div className='w-full mt-10 grid sm:grid-cols-3 gap-4'>
          <div className='w-full p-4 hover:bg-[#FBD2D2] box-border'>
            <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <BiSolidPlaneAlt /> </div>
            <h2 className='text-lg font-semibold mt-3'>Fast Delivery</h2>
            <p className='text-sm mt-1 opacity-70'>Experience the speed of excellence with our swift and reliable delivery services. We pride ourselves on ensuring your shipments reach their destination swiftly, without compromising on safety and quality.</p>
          </div>
          <div className='w-full p-4 hover:bg-[#FBD2D2] box-border'>
            <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <BiSupport /> </div>
            <h2 className='text-lg font-semibold mt-3'>Customer Support</h2>
            <p className='text-sm mt-1 opacity-70'>Our dedicated customer support team is available 24/7 to assist you. Your satisfaction is our priority, and we're here to address your inquiries and concerns with care and expertise.</p>
          </div>
          <div className='w-full p-4 hover:bg-[#FBD2D2] box-border'>
            <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <BsShieldFillCheck /> </div>
            <h2 className='text-lg font-semibold mt-3'>Safe Delivery</h2>
            <p className='text-sm mt-1 opacity-70'>Rest easy with our advanced security measures and vigilant tracking systems. We take every precaution to safeguard your valuable cargo throughout its journey, providing peace of mind at every step.</p>
          </div>
          <div className='w-full p-4 hover:bg-[#FBD2D2] box-border'>
            <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <BiLock /> </div>
            <h2 className='text-lg font-semibold mt-3'>Privacy</h2>
            <p className='text-sm mt-1 opacity-70'>Your data is a treasure, and we treat it as such. Our stringent privacy protocols and commitment to data protection ensure the confidentiality and security of your sensitive information.</p>
          </div>
        </div>
      </div>
      <div className='max-w-[1240px] mx-auto px-[20px]'>
        <div className='rounded-2xl bg-no-repeat bg-center bg-cover overflow-hidden mt-20' style={{backgroundImage: `url(${IMAGES.truck_2})`}} >
          <div className='h-full w-full bg-overlay-30 p-4 sm:p-10 sm:grid grid-cols-2 gap-10'>
            <div className='text-white mt-5 mb-10 sm:mt-20'>
              <h1 className='text-xl font-bold m-0 text-3xl'>Have a parcel that needs to be delivered?</h1>
              <p className='mt-3'>Team of cargo experts are always available to help you with any queries you might have, or if you want to consult in length your logistic requirements. We would study your requirements and</p>
            </div>
            <DeliverParcel />
          </div>
        </div>
      </div>
      <div className='w-full bg-[#f5f5f5] py-20 mt-10'>
        <div className='max-w-[1240px] mx-auto px-[20px]'>
          <h1 className='text-xl font-bold m-0 text-3xl'>Testimonials</h1>
          <p>Here's what our our clients have to say about our excellent service</p>
          <div className='w-full mt-10 flex flex-col sm:grid grid-cols-3 gap-5'>
            {testimonials.map((i, idx) => (
              <div className='w-full h-fit p-5 rounded-lg bg-[#fff]' key={idx}>
                <h2 className='text-lg font-semibold'><span className='text-5xl font-black text-bel-red'>"</span>{i.testimonial}</h2>
                <div className='flex items-center gap-3 mt-5'>
                  {i.img ? <img className='h-[50px] w-[50px] rounded-full object-cover' src={i.img} /> : <div className='h-[50px] w-[50px] rounded-full bg-bel-red text-white font-bold flex items-center justify-center'>{i.abb}</div>}
                  <p className='m-0 font-medium text-base'>{i.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='max-w-[1240px] mx-auto px-[20px] py-20'>
        <h1 className='text-xl font-bold m-0 text-3xl'>Contact <span className='text-bel-red'>Us</span></h1>
        <div className='flex items-center gap-10 flex-wrap mt-6'>
          <div className='flex items-center gap-2'>
            <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <FiMapPin size={18} /> </div> <p>UNIT 6 GRENOGUE BUSINESS PLAZA, Ireland.</p>
          </div>
          <div className='flex items-center gap-2'>
            <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <BsFillTelephoneFill size={16} /> </div> <p>+1 (814) 507-7448</p>
          </div>
          <div className='flex items-center gap-2'>
            <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <FiMail size={20} /> </div> <p>contact@belinawave.com</p>
          </div>
          <div className='flex items-center gap-2'>
            <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#FCA6A6]'> <IoDocumentOutline size={20} /> </div> <p>Registration number: IE482621</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home