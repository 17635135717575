import review_1 from '../../assets/images/review_1.jpg';
import review_2 from '../../assets/images/review_2.jpg'; 

export const testimonials = [
    {
        testimonial: 'I run a small online business, and Belina Wave Logistics has been a game-changer for me. Their affordable rates and quick delivery times have helped me grow my business and keep my customers satisfied.',
        name: 'Christopher Davis',
        img: review_1,
    },
    {
        testimonial: 'The team at Belina Wave Logistics truly understands the logistics industry inside and out. Their expertise and attention to detail have consistently ensured our goods arrive on time and in perfect condition.',
        name: 'Jessica Turner',
        img: review_2
    },
    {
        testimonial: 'Fast and reliable! Belina Wave Logistics consistently delivers our goods faster than we thought possible. Their efficiency has given us a competitive edge in the market, and we look forward to a long-lasting partnership.',
        name: 'Emily Rodriguez',
        abb: 'ER'
    }
]