import React, { useEffect, useState } from 'react';
import { FiArrowDown, FiCheck, FiChevronsDown, FiCopy, FiDownload, FiDownloadCloud } from 'react-icons/fi';
import { IoMdPin } from 'react-icons/io';
import pdf from '../../../../assets/icons/pdf.png';
import { convertTo12HourFormat } from '../../../../helpers/timeConverter.helper';
import QRCode from "react-qr-code";

function Parcel({
    code,
    quantity,
    shipmentTime,
    sender,
    email,
    senderPhone,
    country,
    address,
    weight,
    period,
    reciever,
    recieverAddress,
    recieverCountry,
    recieverEmail,
    recieverPhone,
    location,
    showImage,
    attachedFile
}) {

    const [isCopied, setIsCopied] = useState(false)

    async function copyTextToClipboard(text) {
        return await navigator.clipboard.writeText(text);
    }

    const handleDownload = async (imageSrc) => {
        try {
            const response = await fetch(imageSrc);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
      
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Belina_Express_Reciept'; // Specify the file name for the download
            a.click();
      
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error('Error downloading the image:', error);
          }
      };

    const LocationItem = ({city, comment, date, time, isDone, id, length}) => {
        return(
            <div className='w-full grid grid-cols-[40px_1fr] gap-3'>
                {isDone ?
                    <div className='h-[40px] w-[40px] rounded-full flex items-center bg-bel-red justify-center'> <FiCheck className='text-white text-xl font-bold' /> </div>
                :
                    <div className='flex flex-col items-center '>
                        <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center border'> 
                            {id === length - 1 ?
                            <IoMdPin className='text-bel-red text-xl' /> 
                                :
                            <FiChevronsDown className='text-xl' />
                            }
                        </div>
                        {id !== length - 1 && <div className='flex-1 border-[0.5px]' />}
                    </div>
                }
                <div className='border-t pt-3'>
                    {isDone ? <h1 className='font-semibold text-bel-red'>Shipment has been successfully delivered</h1> : <h1 className='font-semibold'>{city}</h1>}
                    {comment && <p className='text-sm font-medium mt-1'>{comment}</p>}
                    <div className='flex items-center gap-3 mt-3'>
                        <p className='text-sm font-medium'>Date: {date}</p>
                        {time && <p className='text-sm font-medium'>Time: {convertTo12HourFormat(time)}</p>}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='h-fit w-full bg-white rounded-lg border p-6 drop-shadow-xl'>
            <div className='w-full flex  gap-5 items-center'>
                <p className='text-sm'>Tracking code: <span className='font-medium text-base'>{code}</span></p> <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center cursor-pointer hover:bg-[#f5f5f5]' onClick={() => {
                    copyTextToClipboard(code).then(() => {
                        setIsCopied(true)
                    }).catch((err) => {
                        console.log(err);
                    });

                }} > {isCopied ? <FiCheck className='text-[#06CA78]' /> : <FiCopy />} </div>
            </div>
            <p className='text-sm'>Package weight: <span className='font-medium'>{weight}kg</span></p>
            {quantity && <p className='text-sm mt-2'>Quantity: <span className='font-medium'>{quantity}</span></p>}
            {shipmentTime && <p className='text-sm mt-2'>Shipment time: <span className='font-medium'>{convertTo12HourFormat(shipmentTime)}</span></p>}
            {period && <p className='text-sm mt-2'>Delivery Period: <span className='font-medium'>{period}</span></p>}
            <div className='w-full h-fit rounded-md border border-bel-red border-dashed p-5 mt-5 flex flex-col gap-3'>
                <div className='flex items-center text-sm'>
                    <p>Sender:</p><p className='flex-1 text-right font-medium'>{sender}</p>
                </div>
                {email &&
                <div className='flex items-center text-sm'>
                    <p>Email:</p><p className='flex-1 text-right font-medium'>{email}</p>
                </div>
                }
                {senderPhone &&
                <div className='flex items-center text-sm'>
                    <p>Phone number:</p><p className='flex-1 text-right font-medium'>{senderPhone}</p>
                </div>
                }
                <div className='flex items-center text-sm'>
                    <p>Country:</p><p className='flex-1 text-right font-medium'>{country}</p>
                </div>
                <div className='flex items-center text-sm'>
                    <p>Address:</p><p className='flex-1 text-right font-medium'>{address}</p>
                </div>
            </div>
            <div className='h-[10px] w-[51px] mx-auto relative w-[51px] animate-bounce'>
                <div className='h-[50px] w-[50px] rounded-full bg-[#fff] border z-1000 absolute top-[-20px] mx-auto flex items-center justify-center text-bel-red'>
                    <FiArrowDown />
                </div>
            </div>
            <div className='w-full h-fit rounded-md border border-bel-red border-dashed p-5 mt-0 flex flex-col gap-3'>
                <div className='flex items-center text-sm'>
                    <p>Reciever:</p><p className='flex-1 text-right font-medium'>{reciever}</p>
                </div>
                <div className='flex items-center text-sm'>
                    <p>Email:</p><p className='flex-1 text-right font-medium'>{recieverEmail}</p>
                </div>
                <div className='flex items-center text-sm'>
                    <p>phone number:</p><p className='flex-1 text-right font-medium'>{recieverPhone}</p>
                </div>
                <div className='flex items-center text-sm'>
                    <p>Country:</p><p className='flex-1 text-right font-medium'>{recieverCountry}</p>
                </div>
                <div className='flex items-center text-sm'>
                    <p>Address:</p><p className='flex-1 text-right font-medium'>{recieverAddress}</p>
                </div>
            </div>
            {/* <div className='w-full h-[1px] bg-black opacity-20 mt-5'/> */}
            <div className='flex flex-col gap-3 mt-5'>
                {location.map((i, idx) => (
                    <LocationItem
                        city={i.city}
                        comment={i.comment}
                        date={i.date}
                        time={i.time}
                        isDone={i.isCompleted}
                        id={idx}
                        length={location.length}
                        key={idx}
                    />
                ))}
            </div>
            <div className='mt-10 border rounded-lg p-4'>
                {/* <div className='mx-auto w-fit bg-[#ff0000]'>
                    <QRCode value={`https://belinawave.com/tracking/${code}`} />
                </div> */}
                {attachedFile &&
                    <div className='flex items-center gap-3'>
                        <div className='flex-1'>
                            <div className='w-fit flex items-center gap-3 cursor-pointer' onClick={() => showImage(attachedFile)}>
                                <p className='text-[#2367CD] font-medium'>View attached receipt</p> <img src={pdf} alt='' className='h-[30px]' />
                            </div>
                        </div>
                        <div onClick={() => handleDownload(attachedFile)} className='w-[40px] h-[40px] rounded-full bg-[#f5f5f5] flex items-center justify-center cursor-pointer'>
                            <FiDownloadCloud />
                        </div>
                    </div>
                }
            <div>
            </div>
            </div>
        </div>
    )
}

export default Parcel