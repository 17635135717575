import React, { useEffect, useState } from 'react'
import Header from '../../components/common/header/Header'
import Footer from '../../components/common/footer/Footer'
import location_icon from '../../assets/icons/map.png';
import FAQ from '../../components/common/faq/FAQ';
import { selectParcelSlice } from '../../features/parcel/parcel.selector';
import { useDispatch, useSelector } from 'react-redux';
import 'firebase/database';
import { fetchParcelData } from '../../features/parcel/parcel.slice';
import Parcel from '../../components/UI/tracking/parcel/Parcel';
import Loader from '../../components/common/loader/Loader';
import { useParams } from 'react-router-dom';

function Tracking() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [code, setCode] = useState(null);
  const [showImage, setShowImage] = useState(null)

  const { isLoading, parcel, errorMessage } = useSelector(selectParcelSlice);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(id){
      dispatch(fetchParcelData({id: id}))
    }
  }, [id])

  const handleClick = () => {
    dispatch(fetchParcelData({id: code}))
  };

  return (
    <div>
      <Header />
      <div className='max-w-[1240px] mx-auto px-[20px] py-20'>
        <div  className='w-full text-center flex flex-col items-center'>
          <img src={location_icon} className='max-w-[120px] ' />
          <h1 className='font-semibold text-3xl mt-7'>Track Package</h1>
          <p className='font-medium mt-2 opacity-70'>Enter tracking code of package to get real time information about your parcel</p>
          <div className='w-full  md:w-[500px] h-[50px] grid grid-cols-[1fr_150px] items-center gap-5 mt-10'>
            <input className='w-full bg-[#f5f5f5] h-full rounded-full border outline-none focus:border-bel-red px-5' value={code} onChange={e => setCode(e.target.value)} placeholder='Enter tracking code' />
            <button className='h-full w-full px-0 text-sm font-semibold bg-bel-red text-white cursor-pointer rounded-full' onClick={handleClick}>Track Package</button>
          </div>
        </div>
        <div className='max-w-[700px] mx-auto mt-10 text-left'>
          {isLoading && (
            <div>
              <Loader />
            </div>
          )}
          {parcel && (
            <Parcel
              sender={parcel.sender}
              shipmentTime={parcel.shipmentTime}
              quantity={parcel.quantity}
              email={parcel.senderEmail}
              senderPhone={parcel.senderPhone}
              country={parcel.senderCountry}
              address={parcel.senderAddress}
              weight={parcel.weight}
              period={parcel.period}
              reciever={parcel.reciever}
              recieverPhone={parcel.recieverPhone}
              recieverAddress={parcel.recieverAddress}
              recieverCountry={parcel.recieverCountry}
              recieverEmail={parcel.recieverEmail}
              code={parcel.trackingCode}
              location={parcel.location ? parcel.location : []}
              attachedFile={parcel.attachedFile}
              showImage={e => setShowImage(e)}
            />
          )}
          {errorMessage && !isLoading && (
            <div className='text-center text-bel-red font-medium'>
              Error fetching package, Invalid Tracking code
            </div>
          )}
          {showImage &&
            <div className='h-screen w-full bg-overlay-50 fixed top-0 left-0 z-1000 flex items-center justify-center' onClick={() => setShowImage(null)}>
              <img src={showImage} alt='' className='max-w-[80%] max-h-[90%]' />
            </div>
          }
        </div>
      </div>
      <FAQ />
      <Footer />
    </div>
  )
}

export default Tracking