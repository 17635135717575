export function validateData(data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
  
        // Check if the value is undefined, null, or an empty string
        if (value === undefined || value === null || value === "") {
          return `${key} is missing or empty.`;
        }
      }
    }
  
    // All values are present
    return "Data is valid";
}