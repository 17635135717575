import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchParcelDataThunk } from './parcel.thunk';

const initialState = {
    isLoading: false,
    parcel: null,
    errorMessage: null
};

/* Thunk starts here */
export const fetchParcelData = createAsyncThunk("parcel/getAll", fetchParcelDataThunk)
/* Thunk ends here */

const parcelSlice = createSlice({
    name: "parcel",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchParcelData.pending, (state) => {
            state.isLoading = true
            state.errorMessage = null
            state.parcel = null
        })
        .addCase(fetchParcelData.fulfilled, (state, action) => {
            state.isLoading = false
            if(action.payload === null){
                state.errorMessage = 'Error fetching parcel'
            }else{
                state.parcel = action.payload
            }
        })
        .addCase(fetchParcelData.rejected, (state) => {
            state.isLoading = false
            state.errorMessage = 'Error fetching parcel'
        })
    }
});

export default parcelSlice.reducer