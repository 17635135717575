import React, { useState } from 'react'
import Loader from '../../loader/Loader';
import { FiCheck } from 'react-icons/fi';
import countriesList from '../../../../constants/countries/countriesList.json';
import { validateData } from '../../../../helpers/dataValidator';

function DeliverParcel() {
    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    //form data
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("");
    const [pickupCountry, setPickupCountry] = useState("");
    const [pickupCity, setPickupCity] = useState("");
    const [pickupAddress, setPickupAddress] = useState("");
    const [dropoffCountry, setdropoffCountry] = useState("");
    const [dropoffCity, setdropoffCity] = useState("");
    const [dropoffAddress, setdropoffAddress] = useState("");

    const handleSubmit = () => {
        const data = {
            firstname,
            lastname,
            email,
            pickup_address: pickupAddress,
            pickup_country: pickupCountry,
            pickup_city: pickupCity,
            dropoff_country: dropoffCountry,
            dropoff_addess: dropoffAddress,
            dropoff_city: dropoffCity
        };

        if(validateData(data) === "Data is valid"){
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
                setIsDone(true)
            }, 6000);
        }else{
            setErrorMessage(validateData(data))
        }
    }

    if(isLoading)
    return(
        <div className='w-full rounded-xl bg-white p-4'>
            <h1 className='font-semibold text-xl'>Fill in delivery form</h1>
            <div className='flex items-center justify-center  h-[400px]'>
                <Loader />
            </div>
        </div>
    )

    if(isDone)
    return(
        <div className='w-full rounded-xl bg-white px-6 py-20'>
            <div className='flex flex-col items-center justify-center text-center'>
                <div className='h-[120px] w-[120px] rounded-full flex items-center justify-center bg-[#14EFA0] text-4xl text-white border-[12px] border-[#46FEBC]'>
                    <FiCheck />
                </div>
                <h1 className='text-xl font-semibold mt-4'>Delivery Request Sent</h1>
                <p className='mt-1 text-[#353535]'>A quotation with details of your shipping request will be sent to your email: <span className='font-medium text-black'>{email}</span> , this might take a while</p>
            </div>
        </div>
    )

    return (
        <div className='w-full h-fit rounded-xl bg-white p-4'>
            <h1 className='font-semibold text-xl'>Fill in delivery form</h1>
            <div className='grid grid-cols-2 gap-4 mt-6'>
                <div>
                    <p className='text-sm font-medium opacity-80 mb-2'>First name</p>
                    <input className='h-[40px] w-full rounded-md bg-[#f5f5f5] px-3 focus:outline-none border text-sm' onChange={e => setFirstname(e.target.value)} placeholder='Enter first name ...' />
                </div>
                <div>
                    <p className='text-sm font-medium opacity-80 mb-2'>Last name</p>
                    <input className='h-[40px] w-full rounded-md bg-[#f5f5f5] px-3 focus:outline-none border text-sm' onChange={e => setLastname(e.target.value)} placeholder='Enter last name ...' />
                </div>
            </div>
            <div className='mt-4'>
                <p className='text-sm font-medium opacity-80 mb-2'>Email address</p>
                <input type='email' className='h-[40px] w-full rounded-md bg-[#f5f5f5] px-3 focus:outline-none border text-sm' onChange={e => setEmail(e.target.value)} placeholder='Enter email address ...' />
            </div>
            <div className='w-full rounded-md border-[0.5px] border-dashed border-bel-red mt-4 p-3'>
                <p className='text-sm font-medium opacity-80 mb-4'>Pick up location</p>
                <select className='h-[40px] w-full rounded-md bg-[#f5f5f5] px-3 focus:outline-none border text-sm' onChange={e => setPickupCountry(e.target.value)}>
                    <option disabled selected>select a country</option>
                    {countriesList.map((i, idx) => (
                        <option value={i.code} key={idx}>{i.name + " " + i.emoji}</option>
                    ))}
                </select>
                <input name='city' className='h-[40px] w-full rounded-md bg-[#f5f5f5] px-3 focus:outline-none border text-sm mt-4' onChange={e => setPickupCity(e.target.value)} placeholder='State / Region' />
                <input name='address' className='h-[40px] w-full rounded-md bg-[#f5f5f5] px-3 focus:outline-none border text-sm mt-4' onChange={e => setPickupAddress(e.target.value)} placeholder='Enter pick up address ...' />
            </div>
            <div className='w-full rounded-md border-[0.5px] border-dashed border-bel-red mt-4 mb-3 p-3'>
                <p className='text-sm font-medium opacity-80 mb-4'>Drop off location</p>
                <select className='h-[40px] w-full rounded-md bg-[#f5f5f5] px-3 focus:outline-none border text-sm' onChange={e => setdropoffCountry(e.target.value)}>
                    <option disabled selected>select a country</option>
                    {countriesList.map((i, idx) => (
                        <option value={i.code} key={idx}>{i.name + " " + i.emoji}</option>
                    ))}
                </select>
                <input name='city' className='h-[40px] w-full rounded-md bg-[#f5f5f5] px-3 focus:outline-none border text-sm mt-4' onChange={e => setdropoffCity(e.target.value)} placeholder='State / Region' />
                <input name='address' className='h-[40px] w-full rounded-md bg-[#f5f5f5] px-3 focus:outline-none border text-sm mt-4' onChange={e => setdropoffAddress(e.target.value)} placeholder='Enter pick up address ...' />
            </div>
            {errorMessage && <p className='text-bel-red font-medium text-sm capitalize'>{errorMessage}</p>}
            <button className='w-full h-[45px] rounded-full bg-bel-red text-white cursor-pointer mt-3 text-sm font-semibold' onClick={handleSubmit}>Request Pickup</button>
        </div>
    )
}

export default DeliverParcel